import React from 'react'
import Custom from '../Navbar/Custom.js';

function Home() {
  return (
    <div>
     <Custom/>
    </div>
  )
}

export default Home